.card {
  border: 3px solid;
  border-radius: 15px;
  transition: box-shadow 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  padding: 5px;
  background-color: #FFCA69;
}

.card:hover {
  border: 3px solid #151516;
  box-shadow:
    0 3px 3px -2px rgba(0, 0, 0, 0.2),
    0 3px 4px 0 rgba(0, 0, 0, 0.14),
    0 1px 8px 0 rgba(0, 0, 0, 0.12);
}

.project-card {
  padding: 5px;
  background-color: inherit;
}

.project-title {
  padding: 0px;
  margin: 0px;
  font-size: 20px;
  font-weight: bold;
  font-family: Helvetica;
  background-color: inherit;
}

.project-date {
  font-size: 18px;
  font-family: Helvetica;
  background-color: inherit;
}

.project-desc {
  font-size: 18px;
  margin: 0px;
  margin-bottom: 5px;
  padding: 0px;
  font-family: Helvetica;
  background-color: inherit;
}

.a-clear {
  text-decoration: none;
}

.skill-tag-container {
  background-color: inherit;
  display: flex;
  flex-wrap: wrap;
}

.skill-tag {
  display: block;
  padding: 5px;
  margin: 2px;
  font-family: Helvetica;
  background-color: inherit;
  border: 1px solid #f0cd64;
  border-radius: 5px;
  background-color: #FFF2CC
}
