.navDesktop {
  width: 80%;
  min-height: 40px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 10%;
  padding-right: 10%;
  display:flex;
  justify-content: space-between;
}

.nav-link {
  font-size: 30px;
  font-weight:600;
  margin: 0px 5px;
  padding-top: 0px;
  text-decoration: none;
}

.nav-link:hover {
  color: #151516;
  text-decoration: none;
}

.nav-link-underline {
  color: #151516;
  text-decoration: underline;
}





