.skill-select {
    padding-bottom: 30px;
}

.skill-select-title {
    font-size: 24px;
    font-family: Arial, Helvetica, sans-serif;
}


.skill-select-form {
    font-family: Arial, Helvetica, sans-serif;
}

.skill-select-button {
    font-family: inherit;
    padding: 5px; 
    margin-right: 5px;
    margin-bottom: 5px;
    border-radius: 5px;
}

.selected {
    background-color: #FFCA69; 
    border: 1px solid #70582c;
}

.unselected {
    background-color: #FFF2CC;
    border: 1px solid #837959;
}

.unselected:hover {
    background-color: #FFCA69;
    border: 1px solid #837959;
}